import React, { useEffect, useState } from 'react'
import { PaginationInterface } from '@/types'
import { useSearchParams } from 'react-router-dom'
import { getModelValidationLogs } from '@/states/actions'
import {
  DEFAULT_CONTENTS_PAGE_SIZE,
  DEFAULT_VALIDATION_LOGS_PAGE_SIZE,
} from '@/configs'
import { Pagination } from 'antd'

export const ProjectsViewValidLogs = ({
  projectUid,
  modelId,
}: {
  projectUid: string
  modelId: number
}) => {
  // Params
  const [searchParams, setSearchParams] = useSearchParams()
  // State
  const [loading, setLoading] = useState<boolean>(true)
  const [sort, setSort] = useState<string>('DESC')
  const [logs, setLogs] = useState<string[]>([])

  const [pagination, setPagination] = useState<PaginationInterface>({
    total: 0,
    pageSize: searchParams.get('size')
      ? Number(searchParams.get('size'))
      : DEFAULT_VALIDATION_LOGS_PAGE_SIZE,
    current: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
  })

  useEffect(() => {
    fetchLogs(pagination, sort)
  }, [])

  useEffect(() => {
    fetchLogs(pagination, sort)
  }, [projectUid, modelId])

  const fetchLogs = (paging, sortBy) => {
    setLoading(true)

    const req = {
      page: paging.current - 1,
      size: paging.pageSize,
      direction: sortBy,
    }
    getModelValidationLogs(projectUid, modelId, req)
      .then((res) => {
        const logsData = res.data.list as string[]
        logsData.map((log) => log.replaceAll('T', ' '))

        setLogs(logsData)

        setLoading(false)
        const pageInfo = res.data.pageInfo
        setPagination({
          total: pageInfo.totalElements,
          pageSize: pageInfo.size,
          current: pageInfo.page + 1,
        })
      })
      .catch((e) => {
        setLoading(false)
        setLogs([])
        setPagination({
          total: 0,
          pageSize: DEFAULT_CONTENTS_PAGE_SIZE,
          current: 1,
        })
      })
  }

  /**
   * 페이지네이션 변경
   * @param page
   * @param pageSize
   */
  const onHandleChangePagination = (page, pageSize) => {
    fetchLogs(
      {
        total: pagination.total,
        pageSize: pageSize,
        current: page,
        changing: true,
      },
      sort
    )
  }

  return (
    <div className={`h-full`}>
      <div className={`items-center justify-center h-[5%]`}>
        <span className={`block text-right`}>
          배포 횟수: {pagination.total}
        </span>
      </div>
      <div className={`mb-1 overscroll-y-auto h-[90%]`}>
        {logs.map((log, idx) => (
          <span key={idx} className={`block`}>
            {log.replaceAll('T', ' ')}
          </span>
        ))}
      </div>
      <div className={`h-[5%]`}>
        <Pagination
          defaultCurrent={1}
          current={pagination.current}
          total={pagination.total}
          pageSize={pagination.pageSize}
          onChange={onHandleChangePagination}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} / 총 ${total}개`
          }
        />
      </div>
    </div>
  )
}
