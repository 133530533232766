import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ProjectInterface } from '../../types/project.type'

interface ProjectsLogoProps {
  currentProject: ProjectInterface
}

export const ProjectsLogo = ({ currentProject }: ProjectsLogoProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <div
      className={`py-1.5 px-4 border-b cursor-pointer`}
      onClick={() => navigate('/projects')}>
      <div className={`w-full flex items-center space-x-3`}>
        <div
          className={'w-6 h-6 bg-cover bg-gray-300 flex-none'}
          style={{
            backgroundImage: `url(${
              currentProject?.image?.path ? currentProject?.image?.path : ''
            })`,
          }}></div>
        <div className={'grow overflow-hidden'}>
          <div className={'leading-5 truncate'}>{currentProject?.name}</div>
          <div className={'text-xs text-gray-500 leading-4 -mt-0.5 truncate'}>
            {currentProject?.description}
          </div>
        </div>
      </div>
    </div>
  )
}
