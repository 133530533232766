/**
 * API
 */
export const AUTH_URL = `/auth`
export const FILES_URL = `/archive/file`
export const PROJECTS_URL = `/admin/projects`
export const USAGE_URL = `/usage`
export const MEMBERS_URL = `/admin/members`
export const MODELS_URL = `/models`
export const COMPONENTS_URL = `/components`
