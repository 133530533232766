import React, { useEffect, useState } from 'react'
import { Drawer, Table, Tabs } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { ProjectUsageProperties } from '@/configs'
import { ProjectInterface, UserInterface } from '@/types'
import { getMemberProjects } from '@/states/actions/members.actions'
import { getProject } from '@/states/actions'
import { ProjectsView } from '../projects/Projects.view'
import { useTranslation } from 'react-i18next'

interface UsersProps {
  activeUser: UserInterface
}

export const UsersView = ({ activeUser }: UsersProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t, i18n } = useTranslation()

  // State
  const [projects, setProjects] = useState<ProjectInterface[]>([])
  const [activeProject, setActiveProject] = useState<ProjectInterface | null>(
    null
  )
  const [openProject, setOpenProject] = useState(false)

  // Effect
  useEffect(() => {
    if (activeUser) {
      getMemberProjects(activeUser.id).then((res) => {
        setProjects(res.data)
      })
    }
  }, [activeUser])

  /**
   * 유저 기본 정보
   * @param param0
   * @returns
   */
  const UsersViewInfo = ({ activeUser }: UsersProps) => {
    return (
      <div className="space-y-4">
        <div>
          <h4 className="font-medium mb-1">이름</h4>
          <div>{activeUser.name}</div>
        </div>
        <div>
          <h4 className="font-medium mb-1">이메일</h4>
          <p>{activeUser.email}</p>
        </div>
        <div>
          <h4 className="font-medium mb-1">이메일 수신동의</h4>
          <p>{activeUser.newsLetter ? '동의' : '-'}</p>
        </div>
        {/* <div>
          <Button type="primary" danger>
            유저 삭제
          </Button>
        </div> */}
      </div>
    )
  }

  // Table columns
  const tableCols = [
    {
      title: '이름',
      key: 'name',
      render: (project) => (
        <div
          className={'flex items-center space-x-2 cursor-pointer'}
          onClick={() => openProjectDrawer(project)}>
          <div
            className={'w-6 h-6 bg-cover bg-gray-300 flex-none'}
            style={{
              backgroundImage: `url(${project?.image?.path})`,
            }}></div>
          <div className="flex space-x-2 items-center">{project?.name}</div>
        </div>
      ),
    },
    {
      title: '권한',
      key: 'auth',
      render: (project) => (
        <span className="border border-gray-300 rounded-sm px-1 font-normal text-xs">
          {project?.memberList.find((member) => member.id == activeUser.id)
            ? t(
                'roleDesc.' +
                  project?.memberList
                    .find((member) => member.id == activeUser.id)
                    ?.role.toLocaleLowerCase() +
                  '.name'
              )
            : '-'}
        </span>
      ),
    },
    {
      title: '요금제',
      key: 'plan',
      render: (project) => (
        <span className="border border-gray-300 rounded-sm px-1 font-normal text-xs">
          {t(`planTypes.${project.price}.title`)}
        </span>
      ),
    },
    {
      title: '수정일',
      key: 'editedAt',
      render: (project) => (
        <>
          {project.date.editedAt
            ? moment(project?.date.editedAt, 'YYYYMMDDHHmmss').fromNow()
            : '-'}
        </>
      ),
    },
    {
      title: '생성일',
      key: 'createdAt',
      render: (project) => (
        <>
          {moment(project?.date.createdAt, 'YYYYMMDDHHmmss').format(
            'YYYY-MM-DD'
          )}
        </>
      ),
    },
  ]

  /**
   * 유저 프로젝트 목록
   * @param param0
   * @returns
   */
  const UsersViewProjects = ({ activeUser }: UsersProps) => {
    return (
      <Table
        className="w-full"
        // @ts-ignore
        columns={tableCols}
        dataSource={projects}
        rowKey="uid"
        pagination={false}></Table>
    )
  }

  /**
   * 프로젝트 열기
   * @param project
   */
  const openProjectDrawer = (project) => {
    getProject(project.uid).then((res) => {
      const projectSet = res.data
      const languageTuple = Object.keys(projectSet.languageMap).map((key) => [
        key,
        projectSet.languageMap[key],
      ])

      projectSet.defaultLang = languageTuple
        ? // @ts-ignore
          languageTuple.find((tuple) => tuple[1] === true)[0]
        : 'KO'

      projectSet.languageList = [
        projectSet.defaultLang,
        ...languageTuple
          .map((tuple) => tuple[0])
          .filter((lang) => lang !== projectSet.defaultLang),
      ]

      if (projectSet) {
        const projectProperties = ProjectUsageProperties.find(
          (p) => p.price === 'UNLIMITED'
        )
        projectSet.availableRoles = projectProperties?.availableRoles
      }

      setOpenProject(true)
      setActiveProject(projectSet)
    })
  }

  return (
    <div>
      <Tabs
        defaultActiveKey="info"
        items={[
          {
            label: '기본 정보',
            key: 'info',
            children: <UsersViewInfo activeUser={activeUser} />,
          },
          {
            label: `프로젝트 (${projects.length})`,
            key: 'projects',
            children: <UsersViewProjects activeUser={activeUser} />,
          },
        ]}
      />
      {activeProject ? (
        <Drawer
          title={
            <div className={'flex items-center space-x-2'}>
              <div
                className={'w-6 h-6 bg-cover bg-gray-300 flex-none'}
                style={{
                  backgroundImage: `url(${activeProject?.image?.path})`,
                }}></div>
              <div className="flex space-x-2 items-center">
                {activeProject.name}
              </div>
            </div>
          }
          placement="right"
          size="large"
          onClose={() => setOpenProject(false)}
          open={openProject}>
          <ProjectsView activeProject={activeProject} />
        </Drawer>
      ) : (
        <></>
      )}
    </div>
  )
}
