import { AppDispatch } from '@/states/store'
import { ActionTypesProjects } from '@/states/interfaces'
import { axiosInstance, PROJECTS_URL } from '@/configs'
import { LanguagesAvailable } from '@/types'

/**
 * 모든 프로젝트 불러오기
 * @returns {function(*): Promise<void>}
 */
export const getProjects = (req) => {
  return axiosInstance.post(`${PROJECTS_URL}/search`, req)
}

/**
 * 프로젝트 정보 불러오기
 * @returns {function(*): Promise<void>}
 */
export const getProject = (projectUid: string) => {
  return axiosInstance.get(`${PROJECTS_URL}/${projectUid}`)
}

/**
 * 프로젝트 정보 불러오기
 * @returns {function(*): Promise<void>}
 */
export const getProjectAndSet =
  (projectUid: string) => (dispatch: AppDispatch) => {
    return axiosInstance
      .get(`${PROJECTS_URL}/${projectUid}`)
      .then((res) => {
        const projectData = res.data

        axiosInstance
          .get(`${PROJECTS_URL}/${projectUid}/usage`)
          .then((res) => {
            projectData.usage = res.data

            dispatch({
              type: ActionTypesProjects.SET_CURRENT_PROJECT,
              payload: {
                project: projectData,
              },
            })
          })
          .catch((e) => {
            console.log(e)
          })
      })
      .catch((e) => {
        dispatch({
          type: ActionTypesProjects.SET_CURRENT_PROJECT,
          payload: {
            project: null,
          },
        })
      })
  }

/**
 * 프로젝트 요금제 정보 불러오기
 * @returns {function(*): Promise<void>}
 */
export const getProjectUsage =
  (projectUid: string) => (dispatch: AppDispatch) => {
    return axiosInstance
      .get(`${PROJECTS_URL}/${projectUid}/usage`)
      .then((res) => {
        dispatch({
          type: ActionTypesProjects.SET_CURRENT_PROJECT_USAGE,
          payload: {
            usage: res.data,
          },
        })
      })
      .catch((e) => {
        console.log(e)
      })
  }

/**
 * 프로젝트 삭제
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteProject = (id: string) => {
  return axiosInstance.delete(`${PROJECTS_URL}/${id}`)
}

/**
 * 현재 프로젝트 설정
 * @param data
 * @returns {function(*): *}
 */
export const setCurrentProject = (data) => (dispatch: AppDispatch) => {
  return dispatch({
    type: ActionTypesProjects.SET_CURRENT_PROJECT,
    payload: {
      project: data,
    },
  })
}

/**
 * 현재 프로젝트 언어 설정
 * @param lang
 * @returns {function(*): *}
 */
export const setCurrentLanguage = (lang: LanguagesAvailable) => (dispatch) => {
  return dispatch({
    type: ActionTypesProjects.SET_CURRENT_LANGUAGE,
    payload: {
      language: lang,
    },
  })
}

/**
 * 프로젝트 멤버 불러오기
 * @returns {function(*): Promise<void>}
 */
export const getProjectMembers =
  (projectUid: string) => (dispatch: AppDispatch) => {
    return axiosInstance
      .get(`${PROJECTS_URL}/${projectUid}/members`)
      .then((res) => {
        dispatch({
          type: ActionTypesProjects.SET_CURRENT_PROJECT,
          payload: {
            project: res.data,
          },
        })
      })
      .catch((e) => {
        dispatch({
          type: ActionTypesProjects.SET_CURRENT_PROJECT,
          payload: {
            project: null,
          },
        })
      })
  }

/**
 * 프로젝트 멤버 추가
 * @param projectUid
 * @param req
 * @returns {Promise<AxiosResponse<any>>}
 */
export const addProjectMembers = (projectUid, req) => {
  return axiosInstance.post(`${PROJECTS_URL}/${projectUid}/members`, req)
}

/**
 * 프로젝트 멤버 권한 수정
 * @param projectUid
 * @param req
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateProjectMembers = (projectUid, req) => {
  return axiosInstance.put(`${PROJECTS_URL}/${projectUid}/members`, req)
}

/**
 * 프로젝트 소속 멤버 삭제
 * @param projectUid
 * @param memberIdList
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteProjectMembers = (projectUid, memberIdList) => {
  return axiosInstance.delete(`${PROJECTS_URL}/${projectUid}/members`, {
    data: memberIdList,
  })
}

/**
 * 프로젝트 요금제 수정
 * @param projectUid
 * @param req
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateProjectPrice = (projectUid, req) => {
  return axiosInstance.put(`${PROJECTS_URL}/${projectUid}/price`, req, {
    headers: {
      'Content-Type': 'text/plain',
    },
  })
}
