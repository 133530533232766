import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Card, message, Modal, Select, Typography } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { RootState } from '@/states/reducers'
import { ProjectsUsageCircle } from './Projects.usage.circle'
import { ProjectsUsageBar } from './Projects.usage.bar'
import { ProjectUsageProperties } from '@/configs'
import { getProjectAndSet, updateProjectPrice } from '@/states/actions'
import { useAppDispatch } from '@/states/store'

export const ProjectsViewUsage = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { currentProject, modelList } = projectsState

  /**
   * 요금제 수정
   * @param member
   * @param val
   */
  const onPriceEdit = (val) => {
    Modal.confirm({
      centered: true,
      title: t('confirmChangePlanTitle'),
      icon: <ExclamationCircleOutlined />,
      content: t('confirmChangePlanDesc'),
      okText: t('change'),
      cancelText: t('cancel'),
      onOk() {
        return new Promise((resolve, reject) => {
          if (currentProject) {
            updateProjectPrice(currentProject.uid, val)
              .then(async (res) => {
                await resolve(dispatch(getProjectAndSet(currentProject.uid)))
                await message.success(t('projectPlanUpdated'))
              })
              .catch((e) => {
                message.error(e.response.data.error)
              })
          }
        }).catch((e) => console.log(e))
      },
      onCancel() {},
    })
  }

  return currentProject ? (
    <div className={'space-y-10'}>
      {/* Setting body: 시작 */}
      <div className={'space-y-6'}>
        <div className="grid grid-cols-2">
          <Card>
            <div className="p-3">
              <div className="mb-6">
                <Typography.Title level={5} className={'mt-0'}>
                  {t('projectPlan')}
                </Typography.Title>
              </div>
              <div>
                <Select
                  className="w-48"
                  value={currentProject.price}
                  onChange={(val) => onPriceEdit(val)}>
                  {ProjectUsageProperties.map((plan) => (
                    <Select.Option key={plan.price} value={plan.price}>
                      {t(`planTypes.${plan.price}.title`)}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          </Card>
        </div>
        <Card>
          <div className="p-3">
            <div className="mb-6">
              <Typography.Title level={5} className={'mt-0'}>
                {t('projectGlobalUsage')}
              </Typography.Title>
            </div>
            <div className="2xl:px-10">
              <div className="grid grid-cols-3 xl:grid-cols-5">
                <div className="border-r border-gray-300">
                  <ProjectsUsageCircle
                    usage={currentProject?.usage?.content}
                    label={t('noGloablContents')}
                    desc={t('noGloablContentsDesc')}
                  />
                </div>
                <div className="border-r border-gray-300">
                  <ProjectsUsageCircle
                    usage={currentProject?.usage?.storage}
                    label={t('mediaStorage')}
                    desc={t('mediaStorageDesc')}
                    isFile
                  />
                </div>
                <div className="border-r border-gray-300">
                  <ProjectsUsageCircle
                    usage={currentProject?.usage?.model}
                    label={t('noModels')}
                    desc={t('noModelsDesc')}
                  />
                </div>
                <div className="border-r border-gray-300">
                  <ProjectsUsageCircle
                    usage={currentProject?.usage?.language}
                    label={t('noLanguages')}
                    desc={t('noLanguagesDesc')}
                  />
                </div>
                <div className="">
                  <ProjectsUsageCircle
                    usage={currentProject?.usage?.member}
                    label={t('noMembers')}
                    desc={t('noMembersDesc')}
                  />
                </div>
              </div>
            </div>
          </div>
        </Card>
        {currentProject.usage?.modelList &&
        currentProject.usage?.modelList.length ? (
          <div className="grid grid-cols-1">
            <Card>
              <div className="p-3 text-sm">
                <div className="mb-6">
                  <Typography.Title level={5} className={'mt-0'}>
                    {t('usageByModel')}
                  </Typography.Title>
                </div>
                <div className="space-y-3">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center space-x-1">
                      <span>{t('limitComponentByModel')}</span>
                      {/* <InfoCircleOutlined /> */}
                    </div>
                    <div>
                      {currentProject.usage.type !== 'UNLIMITED'
                        ? currentProject.usage?.modelList[0].component.limit.toLocaleString() +
                          t('countWord')
                        : t('unlimited')}
                    </div>
                  </div>
                  <div className="flex justify-between items-center">
                    <div className="flex items-center space-x-1">
                      <span>{t('maxContentsByModel')}</span>
                      {/* <InfoCircleOutlined /> */}
                    </div>
                    <div>
                      {currentProject.usage.type !== 'UNLIMITED'
                        ? currentProject.usage?.modelList[0].content.limit.toLocaleString() +
                          t('countWord')
                        : t('unlimited')}
                    </div>
                  </div>
                </div>
                <hr className="mt-6 mb-6" />
                {currentProject.usage?.modelList.map((model) => (
                  <div key={model.id}>
                    <div className="grid grid-cols-8 gap-6">
                      <div className="col-span-3">
                        {
                          modelList.find((m) => m.id === model.id)?.languageMap[
                            currentProject.defaultLang
                          ]
                        }
                      </div>
                      <div className="col-span-5 space-y-4">
                        <ProjectsUsageBar
                          usage={model.component}
                          label={t('noComponents')}
                        />
                        <ProjectsUsageBar
                          usage={model.content}
                          label={t('noContents')}
                        />
                      </div>
                    </div>
                    <hr className="mt-6 mb-6" />
                  </div>
                ))}
              </div>
            </Card>
          </div>
        ) : (
          <></>
        )}
      </div>
      {/* Setting body: 끝 */}
    </div>
  ) : (
    <></>
  )
}
