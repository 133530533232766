import {
  CellInterface,
  ComponentInterface,
  ContentsInterface,
  MediaInterface,
  ModelInterface,
  ProjectAccessInterface,
  ProjectInterface,
} from '@/types'

export enum ActionTypesModals {
  SET_ACCOUNT_PASSWORD_MODAL = 'SET_ACCOUNT_PASSWORD_MODAL', // 비밀번호 변경 폼
  SET_PROJECT_FORM_MODAL = 'SET_PROJECT_FORM_MODAL', // 프로젝트 폼
  SET_PROJECT_INVITE_MODAL = 'SET_PROJECT_INVITE_MODAL', // 프로젝트 초대
  SET_PROJECT_ACCESS_FORM_MODAL = 'SET_PROJECT_ACCESS_FORM_MODAL', // API 키값 설정
  SET_MODEL_FORM_MODAL = 'SET_MODEL_FORM_MODAL', // 모델 폼
  SET_COMPONENT_FORM_MODAL = 'SET_COMPONENT_FORM_MODAL', // 컴포넌트 폼
  SET_MEDIA_UPLOAD_MODAL = 'SET_MEDIA_UPLOAD_MODAL', // 미디어 업로드
  SET_MEDIA_FORM_MODAL = 'SET_MEDIA_FORM_MODAL', // 미디어 폼
  SET_MEDIA_SELECTOR_MODAL = 'SET_MEDIA_SELECTOR_MODAL', // 미디어 셀렉터 폼
  SET_CONTENTS_RELATION_SELECTOR_MODAL = 'SET_CONTENTS_RELATION_SELECTOR_MODAL', // 연관 콘텐츠 셀렉터 폼
  SET_CONTENTS_FORM_MODAL = 'SET_CONTENTS_FORM_MODAL', // 콘텐츠 폼
}

export type ActionsModals =
  | ModalsSetAccountPassword
  | ModalsSetProjectForm
  | ModalsSetProjectInvite
  | ModalsSetProjectApiKeyForm
  | ModalsSetModelForm
  | ModalsSetComponentForm
  | ModalsSetMediaUpload
  | ModalsSetMediaForm
  | ModalsSetContentsForm
  | ModalsSetContentsRelationSelectorForm
  | ModalsSetMediaSelectorForm

export interface ModalsSetAccountPassword {
  type: ActionTypesModals.SET_ACCOUNT_PASSWORD_MODAL
  payload: {
    isOpen: boolean
  }
}

export interface ModalsSetProjectForm {
  type: ActionTypesModals.SET_PROJECT_FORM_MODAL
  payload: {
    isOpen: boolean
    info?: ProjectInterface
  }
}

export interface ModalsSetProjectInvite {
  type: ActionTypesModals.SET_PROJECT_INVITE_MODAL
  payload: {
    isOpen: boolean
  }
}

export interface ModalsSetProjectApiKeyForm {
  type: ActionTypesModals.SET_PROJECT_ACCESS_FORM_MODAL
  payload: {
    isOpen: boolean
    info?: ProjectAccessInterface
  }
}

export interface ModalsSetModelForm {
  type: ActionTypesModals.SET_MODEL_FORM_MODAL
  payload: {
    isOpen: boolean
    info?: ModelInterface
  }
}

export interface ModalsSetComponentForm {
  type: ActionTypesModals.SET_COMPONENT_FORM_MODAL
  payload: {
    isOpen: boolean
    info?: ComponentInterface
    parentInfo?: ComponentInterface
  }
}

export interface ModalsSetMediaUpload {
  type: ActionTypesModals.SET_MEDIA_UPLOAD_MODAL
  payload: {
    isOpen: boolean
    uid?: string
  }
}

export interface ModalsSetMediaForm {
  type: ActionTypesModals.SET_MEDIA_FORM_MODAL
  payload: {
    isOpen: boolean
    info?: MediaInterface
    updatedInfo?: MediaInterface
    uid?: string
  }
}

export interface ModalsSetMediaSelectorForm {
  type: ActionTypesModals.SET_MEDIA_SELECTOR_MODAL
  payload: {
    isOpen: boolean
    info?: CellInterface
    componentInfo?: ComponentInterface
    contentsInfo?: ContentsInterface
  }
}

export interface ModalsSetContentsRelationSelectorForm {
  type: ActionTypesModals.SET_CONTENTS_RELATION_SELECTOR_MODAL
  payload: {
    isOpen: boolean
    info?: CellInterface
    componentInfo?: ComponentInterface
    contentsInfo?: ContentsInterface
  }
}

export interface ModalsSetContentsForm {
  type: ActionTypesModals.SET_CONTENTS_FORM_MODAL
  payload: {
    isOpen: boolean
    info?: ContentsInterface
  }
}
