import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Spin } from 'antd'

const Home = () => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    navigate('/projects')
  }, [])

  return (
    <div>
      <Spin />
    </div>
  )
}

export default Home
