import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button, Col, message, Modal, Row, Select, Typography } from 'antd'
import { CheckOutlined, CloseCircleOutlined } from '@ant-design/icons'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { RootState } from '@/states/reducers'
import { setProjectInviteModal } from '@/states/actions/modals.actions'
import { LAYOUT_MODAL_WIDTH } from '@/configs'
import {
  addProjectMembers,
  getProjectAndSet,
} from '@/states/actions/projects.actions'
import { searchEmail } from '@/states/actions/auth.actions'
import { AlertStatus } from '@/components/common/Alert'
import { ProjectRoleType } from '@/types'
import { LabeledValue } from 'antd/es/select'
import { useAppDispatch } from '@/states/store'

const initialProjectFormValues = {}

export const ProjectsInviteModal = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { modalsState, projectsState } = useSelector(
    (state: RootState) => ({
      modalsState: state.modals,
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { projectInviteModal } = modalsState
  const { currentProject } = projectsState

  // State
  const [role, setRole] = useState<ProjectRoleType>('ADMIN')
  const [memberList, setMemberList] = useState<LabeledValue[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [adding, setAdding] = useState<boolean>(false)

  // Effect
  useEffect(() => {
    if (projectInviteModal) {
      setTimeout(() => {
        document.getElementById('projectInviteMembers')?.focus()
      })
    } else {
      resetForm()
    }
  }, [projectInviteModal])

  // Validation
  const validationProjectFormSchema = Yup.object().shape({})

  // Formik
  const formikProjectInvite = useFormik({
    initialValues: initialProjectFormValues,
    validationSchema: validationProjectFormSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        if (currentProject) {
          setStatus(null)
          setLoading(true)

          addProjectMembers(
            currentProject.uid,
            memberList.map((ma) => {
              return { memberId: ma.value, role: role }
            })
          ).then(async (res) => {
            await dispatch(getProjectAndSet(currentProject.uid))
            await setLoading(false)
            await dispatch(setProjectInviteModal(false))
            message.success(t('membersInvited'))
          })
        }
      } catch (e) {
        setLoading(false)
        setSubmitting(false)
        // @ts-ignore
        setStatus(e.response.data.error)
      }
    },
  })

  /**
   * 회원 변경
   * @param val
   */
  const onMemberChange = (val) => {
    // 등록
    if (val.length > memberList.length) {
      const emailChecked = val[val.length - 1]

      // 이미 입력한 회원 확인
      if (memberList.map((m) => m.label).indexOf(emailChecked) >= 0) {
        return false
      }
      // 이미 등록한 회원 확인
      else if (
        currentProject &&
        currentProject.memberList.map((m) => m.email).indexOf(emailChecked) >= 0
      ) {
        message.info(t('alreadyMemberInvited'))
      }
      // 회원 확인
      else if (
        emailChecked &&
        /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(emailChecked)
      ) {
        setAdding(true)

        const req = {
          email: emailChecked,
        }

        searchEmail(req)
          .then((res) => {
            setMemberList([
              ...memberList,
              {
                label: res.data.email,
                value: res.data.id,
              },
            ])
            setAdding(false)
          })
          .catch((e) => {
            console.log(e)
            setAdding(false)
            message.error(t('noMemberFound'))
          })
          .then(() => {
            setTimeout(() => {
              document.getElementById('projectInviteMembers')?.focus()
            })
          })
      } else {
        message.info(t('validation.email'))
      }
    }
    // 제거
    else {
      setMemberList(val)
    }
  }

  /**
   * 폼 리셋
   */
  const resetForm = () => {
    formikProjectInvite.resetForm()
    setLoading(false)
    setMemberList([])

    setTimeout(() => {
      document.getElementById('projectInviteMembers')?.focus()
    })
  }

  return (
    <Modal
      width={LAYOUT_MODAL_WIDTH}
      closeIcon={<CloseCircleOutlined title={t('close')} />}
      open={projectInviteModal}
      maskClosable={false}
      onCancel={() => dispatch(setProjectInviteModal(false))}
      title={t('inviteMembers')}
      footer={[
        <div key={'footer'} className={'flex justify-between items-center'}>
          <div>
            <Button
              type="primary"
              ghost
              onClick={() => dispatch(setProjectInviteModal(false))}>
              {t('cancel')}
            </Button>
          </div>
          <div>
            <Button
              type={'primary'}
              icon={<CheckOutlined />}
              onClick={() => formikProjectInvite.submitForm()}
              disabled={loading || memberList.length === 0}
              loading={loading}>
              {t('invite')}
            </Button>
          </div>
        </div>,
      ]}>
      <>
        <form onSubmit={formikProjectInvite.handleSubmit} method="POST">
          <AlertStatus
            status={formikProjectInvite.status}
            onClick={() => formikProjectInvite.setStatus(null)}></AlertStatus>
          <div className={'space-y-6'}>
            <div className={'border-b pb-1.5'}>
              <Typography.Title level={5} className={'mt-0 mb-3'}>
                {t('inviteMembers')}
              </Typography.Title>
            </div>
            <div>
              <Row gutter={0} className={'border rounded-sm'}>
                <Col span={18}>
                  <Select
                    id={'projectInviteMembers'}
                    bordered={false}
                    value={memberList}
                    optionLabelProp={'email'}
                    disabled={adding}
                    loading={adding}
                    mode={'tags'}
                    className={'w-full'}
                    placeholder={t('members')}
                    onChange={onMemberChange}></Select>
                </Col>
                <Col span={6} className={'pl-3'}>
                  <Select
                    defaultValue={'ADMIN'}
                    className={'w-full'}
                    onChange={(val) => setRole(val as ProjectRoleType)}
                    dropdownMatchSelectWidth={200}
                    bordered={false}>
                    {currentProject?.availableRoles?.map((role) => (
                      <Select.Option key={role} value={role}>
                        <div>
                          <div>
                            {t(
                              'roleDesc.' + role.toLocaleLowerCase() + '.name'
                            )}
                          </div>
                          <div
                            className={
                              'text-xs text-gray-500 overflow-visible'
                            }>
                            {t(
                              'roleDesc.' + role.toLocaleLowerCase() + '.desc'
                            )}
                          </div>
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
              </Row>
              <p className="text-gray-500 mt-1">{t('enterEmailDesc')}</p>
            </div>
          </div>
        </form>
      </>
    </Modal>
  )
}
