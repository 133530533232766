import React from 'react'
import { Sidebar } from './Sidebar'
import { Layout } from 'antd'
import { LAYOUT_GNB_WIDTH } from '@/configs'

export const DefaultLayout = ({ children }) => {
  return (
    <Layout>
      <Sidebar currentProject={null} />
      <main
        className="pt-8 pb-10 px-6"
        style={{
          marginLeft: LAYOUT_GNB_WIDTH,
        }}>
        {children}
      </main>
    </Layout>
  )
}
