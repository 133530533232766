import { MediaListViewType, ProjectListViewType } from '@/types'

export enum ActionTypesLayout {
  TOGGLE_GNB = 'TOGGLE_GNB', // GNB 설정
  TOGGLE_USER_PROFILE_TAB = 'TOGGLE_USER_PROFILE_TAB', // 유저 프로필 설정
  SET_ACCOUNT_MENU = 'SET_ACCOUNT_MENU', // 계정 메뉴 설정
  SET_MORE_MENU = 'SET_MORE_MENU', // 더보기 메뉴 설정
  SET_SIDEBAR = 'SET_SIDEBAR', // 사이드바 설정
  SET_SPLIT_SIZE = 'SET_SPLIT_SIZE', // Split 크기 설정
  SET_PROJECT_LIST_VIEW_TYPE = 'SET_PROJECT_LIST_VIEW_TYPE', // 프로젝트 목록 보기 타입 설정
  SET_MEDIA_LIST_VIEW_TYPE = 'SET_MEDIA_LIST_VIEW_TYPE', // 미디어 콘텐츠 보기 타입 설정
}

export type ActionsLayout =
  | LayoutToggleGnb
  | LayoutToggleUserProfileTab
  | LayoutSetAccountMenu
  | LayoutSetMoreMenu
  | LayoutSetSidebar
  | LayoutSetSplitSize
  | LayoutSetProjectListViewType
  | LayoutSetMediaListViewType

export interface LayoutToggleGnb {
  type: ActionTypesLayout.TOGGLE_GNB
  payload: {
    isCollapse: boolean
  }
}

export interface LayoutToggleUserProfileTab {
  type: ActionTypesLayout.TOGGLE_USER_PROFILE_TAB
  payload: {
    isOpen: boolean
  }
}

export interface LayoutSetAccountMenu {
  type: ActionTypesLayout.SET_ACCOUNT_MENU
  payload: {
    isOpen: boolean
  }
}

export interface LayoutSetMoreMenu {
  type: ActionTypesLayout.SET_MORE_MENU
  payload: {
    isOpen: boolean
  }
}

export interface LayoutSetSidebar {
  type: ActionTypesLayout.SET_SIDEBAR
  payload: {
    isOpen: boolean
  }
}

export interface LayoutSetSplitSize {
  type: ActionTypesLayout.SET_SPLIT_SIZE
  payload: {
    splitSize: number[]
  }
}

export interface LayoutSetProjectListViewType {
  type: ActionTypesLayout.SET_PROJECT_LIST_VIEW_TYPE
  payload: {
    viewType: ProjectListViewType
  }
}

export interface LayoutSetMediaListViewType {
  type: ActionTypesLayout.SET_MEDIA_LIST_VIEW_TYPE
  payload: {
    viewType: MediaListViewType
  }
}
