import { AppDispatch } from '@/states/store'
import { ActionTypesProjects } from '@/states/interfaces'
import { axiosInstance, PROJECTS_URL } from '@/configs'

/**
 * 프로젝트 모델 불러오기
 * @param projectId
 * @param initModelId
 * @param isPreserveContents
 * @returns
 */
export const getModels =
  (
    projectId,
    initModelId: number | null | boolean = null,
    isPreserveContents = false
  ) =>
  (dispatch: AppDispatch) => {
    return axiosInstance
      .get(`${PROJECTS_URL}/${projectId}/models`)
      .then((res) => {
        dispatch({
          type: ActionTypesProjects.SET_MODEL_LIST,
          payload: {
            models: res.data,
          },
        })

        if (initModelId) {
          const targetModel = res.data.list.find((i) => i.id === initModelId)

          dispatch({
            type: ActionTypesProjects.SET_CURRENT_MODEL,
            payload: {
              model: targetModel ? targetModel : res.data.list[0],
              isPreserveContents: isPreserveContents,
            },
          })
        }
      })
      .catch((e) => {
        dispatch({
          type: ActionTypesProjects.SET_MODEL_LIST,
          payload: {
            models: [],
          },
        })
      })
  }

/**
 * 프로젝트 모델 불러오기 (Data)
 * @param projectId
 * @param initModelId
 * @returns
 */
export const getModelsData = (projectId) => {
  return axiosInstance.get(`${PROJECTS_URL}/${projectId}/models`)
}

/**
 * 모델 목록 설정
 * @param list
 */
export const setModelsList = (list) => (dispatch: AppDispatch) => {
  dispatch({
    type: ActionTypesProjects.SET_MODEL_LIST,
    payload: {
      models: list,
    },
  })
}

/**
 * 모델 정보 불러오기
 * @param projectId
 * @param id
 * @returns
 */
export const getModel = (projectId, id) => (dispatch: AppDispatch) => {
  return axiosInstance
    .get(`${PROJECTS_URL}/${projectId}/models/${id}`)
    .then((res) => {
      dispatch({
        type: ActionTypesProjects.SET_CURRENT_PROJECT,
        payload: {
          project: res.data,
        },
      })
    })
    .catch((e) => {
      dispatch({
        type: ActionTypesProjects.SET_CURRENT_PROJECT,
        payload: {
          project: null,
        },
      })
    })
}

/**
 * 모델 정보 불러오기 (data)
 * @param projectId
 * @param id
 * @returns
 */
export const getModelData = (projectId, id) => {
  return axiosInstance.get(`${PROJECTS_URL}/${projectId}/models/${id}`)
}

/**
 * 모델 추가
 * @param projectId
 * @param req
 * @returns
 */
export const createModel = (projectId, req) => {
  return axiosInstance.post(`${PROJECTS_URL}/${projectId}/models`, req)
}

/**
 * 모델 수정
 * @param projectId
 * @param id
 * @param req
 * @returns
 */
export const updateModel = (projectId, id, req) => {
  return axiosInstance.put(`${PROJECTS_URL}/${projectId}/models/${id}`, req)
  /* return axios
                    .put(`${PROJECTS_URL}/${projectId}/models/${id}`, req)
                    .then((res) => {
                      dispatch({
                        type: ActionTypesProjects.SET_CURRENT_MODEL,
                        payload: {
                          model: res.data,
                        },
                      })
                    })
                    .catch((e) => {
                      console.log(e)
                    }) */
}

/**
 * 모델 검증 & 배포
 * @param projectId
 * @param modelId
 * @returns
 */
export const validateModel = (projectId, modelId) => {
  return axiosInstance.post(
    `${PROJECTS_URL}/${projectId}/models/${modelId}/validate`
  )
}

/**
 * 모델 삭제
 * @param projectId
 * @param id
 * @returns
 */
export const deleteModel = (projectId, id) => {
  return axiosInstance.delete(`${PROJECTS_URL}/${projectId}/models/${id}`)
}

/**
 * 현재 모델 설정
 * @param data
 * @param isPreserveContents
 * @returns {function(*): *}
 */
export const setCurrentModel =
  (data, isPreserveContents = false) =>
  (dispatch) => {
    return dispatch({
      type: ActionTypesProjects.SET_CURRENT_MODEL,
      payload: {
        model: data,
        isPreserveContents: isPreserveContents,
      },
    })
  }

/**
 * 임시 모델 설정
 * @param data

 * @returns {function(*): *}
 */
export const setTmpModel = (data) => (dispatch) => {
  return dispatch({
    type: ActionTypesProjects.SET_TMP_MODEL,
    payload: {
      model: JSON.parse(JSON.stringify(data)),
    },
  })
}

/**
 * 모델 배포 로그 불러오기
 * @param projectId
 * @param id
 * @param req
 */
export const getModelValidationLogs = (projectId, id, req) => {
  return axiosInstance.post(
    `${PROJECTS_URL}/${projectId}/models/${id}/logs`,
    req
  )
}
