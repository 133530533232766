const baseName = 'memexIndexedDB'
const storeName = 'contents'

function logerr(err) {
  console.log(err)
}

/**
 * DB 연결
 * @param {*} f
 */
export function connectDB(f) {
  const indexedDB =
    window.indexedDB ||
    window.mozIndexedDB ||
    window.webkitIndexedDB ||
    window.msIndexedDB ||
    window.shimIndexedDB

  // Open (or create) the database
  const request = indexedDB.open(baseName, 1)
  request.onerror = logerr
  request.onsuccess = function () {
    f(request.result)
  }
  request.onupgradeneeded = function (e) {
    const Db = e.currentTarget.result

    //Create store
    if (!Db.objectStoreNames.contains(storeName)) {
      const store = Db.createObjectStore(storeName, {
        autoIncrement: false,
      })
    }
    connectDB(f)
  }
}

/**
 * Model ID로 아이템 가져오기
 * @param {*} modelId
 * @returns
 */
export function getItem(modelId) {
  return new Promise(function (resolve, reject) {
    connectDB(function (db) {
      const transaction = db
        .transaction([storeName], 'readonly')
        .objectStore(storeName)
        .get(modelId)
      transaction.onerror = logerr
      transaction.onsuccess = function () {
        resolve(transaction.result ? transaction.result : -1)
      }
    })
  })
}

/**
 * 아이템 추가
 * @param {*} modelId
 * @param {*} obj
 */
export function addItem(modelId, obj) {
  connectDB(function (db) {
    const transaction = db.transaction([storeName], 'readwrite')
    const objectStore = transaction.objectStore(storeName)
    const req = objectStore.add(obj, modelId)
    req.onerror = logerr
    req.onsuccess = function () {
      return req.result
    }
  })
}

/**
 * 아이템 수정
 * @param {*} modelId
 * @param {*} obj
 */
export function updateItem(modelId, obj) {
  connectDB(function (db) {
    const transaction = db.transaction([storeName], 'readwrite')
    const objectStore = transaction.objectStore(storeName)
    const req = objectStore.get(modelId)
    req.onerror = logerr
    req.onsuccess = (e) => {
      let data = e.target.result
      data = obj

      const updateRequest = objectStore.put(data, modelId)
      updateRequest.onerror = logerr
      updateRequest.onsuccess = () => {
        return updateRequest.result
      }
    }
  })
}

/**
 * 아이템 삭제
 * @param {*} modelId
 * @returns
 */
export function deleteItem(modelId) {
  return connectDB(function (db) {
    const transaction = db.transaction([storeName], 'readwrite')
    const objectStore = transaction.objectStore(storeName)
    const req = objectStore.delete(modelId)
    req.onerror = logerr
    req.onsuccess = function () {
      return true
    }
  })
}
