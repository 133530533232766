import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const CommonLogo = () => {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <div
      className={'py-4 px-6 border-b cursor-pointer'}
      onClick={() => navigate('/')}>
      <div className="w-full flex items-center space-x-3">
        <div className="w-28 h-6 memex-logo horizontal flex-none"></div>
      </div>
    </div>
  )
}
