import {
  ActionsLayout,
  ActionTypesLayout,
  LayoutState,
} from '@/states/interfaces'
import {
  MEDIA_LIST_VIEW_MODE_KEY,
  PROJECT_LIST_VIEW_MODE_KEY,
  SPLIT_KEY,
} from '@/configs'
import Cookies from 'js-cookie'
import { defaultSplitView } from '@/types'

const splitVal =
  Cookies.get(SPLIT_KEY) && Cookies.get(SPLIT_KEY)?.split(',').length === 2
    ? Cookies.get(SPLIT_KEY)
        ?.split(',')
        .map((s) => Number(s))
    : defaultSplitView

export const initialState: LayoutState = {
  accountMenu: false,
  gnb: true,
  sidebar: false,
  splitSize:
    splitVal &&
    typeof splitVal[0] === 'number' &&
    !isNaN(splitVal[0]) &&
    typeof splitVal[1] === 'number' &&
    !isNaN(splitVal[1])
      ? splitVal
      : defaultSplitView,
  projectListView: !Cookies.get(PROJECT_LIST_VIEW_MODE_KEY)
    ? 'card'
    : (Cookies.get(PROJECT_LIST_VIEW_MODE_KEY) as string),
  mediaListView: !Cookies.get(MEDIA_LIST_VIEW_MODE_KEY)
    ? 'card'
    : (Cookies.get(MEDIA_LIST_VIEW_MODE_KEY) as string),
}

interface HydratePayload {
  layout: LayoutState
}

const layoutReducer = (
  state = initialState,
  action: ActionsLayout
): LayoutState => {
  switch (action.type) {
    // 계정 메뉴 설정
    case ActionTypesLayout.SET_ACCOUNT_MENU: {
      return {
        ...state,
        accountMenu: action.payload.isOpen,
      }
    }
    // GNB 설정
    case ActionTypesLayout.TOGGLE_GNB: {
      return {
        ...state,
        gnb: action.payload.isCollapse,
      }
    }
    // 사이드바 설정
    case ActionTypesLayout.SET_SIDEBAR: {
      return {
        ...state,
        sidebar: action.payload.isOpen,
      }
    }
    // Split 크기 설정
    case ActionTypesLayout.SET_SPLIT_SIZE: {
      let size = action.payload.splitSize

      Cookies.set(SPLIT_KEY, size[0] + ',' + size[1], {
        expires: 86400,
      })

      return {
        ...state,
        splitSize: size,
      }
    }
    // 프로젝트 목록 보기 타입 설정
    case ActionTypesLayout.SET_PROJECT_LIST_VIEW_TYPE: {
      Cookies.set(PROJECT_LIST_VIEW_MODE_KEY, action.payload.viewType, {
        expires: 365,
      })

      return {
        ...state,
        projectListView: action.payload.viewType,
      }
    }
    // 미디어 콘텐츠 목록 보기 타입 설정
    case ActionTypesLayout.SET_MEDIA_LIST_VIEW_TYPE: {
      Cookies.set(MEDIA_LIST_VIEW_MODE_KEY, action.payload.viewType, {
        expires: 365,
      })

      return {
        ...state,
        mediaListView: action.payload.viewType,
      }
    }
    default:
      return state
  }
}

export default layoutReducer
