import { axiosInstance, MEMBERS_URL } from '@/configs'

/**
 * 모든 회원 불러오기
 * @returns {function(*): Promise<void>}
 */
export const getMembers = (req) => {
  return axiosInstance.post(`${MEMBERS_URL}/search`, req)
}

/**
 * 회원이 가입한 프로젝트 목록
 * @returns {function(*): Promise<void>}
 */
export const getMemberProjects = (memberId) => {
  return axiosInstance.get(`${MEMBERS_URL}/${memberId}/projects`)
}
