import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Collapse, Drawer } from 'antd'
import { ComponentsItem } from '../components/components.item'
import { RootState } from '@/states/reducers'
import { useAppDispatch } from '@/states/store'
import { ProjectsViewValidLogs } from '@/components/projects/Projects.view.validLogs'

const { Panel } = Collapse

/**
 * 프로젝트 모델 목록 보기
 * @param param0
 * @returns
 */
export const ProjectsViewModels = () => {
  const dispatch = useAppDispatch()

  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { currentProject, modelList } = projectsState
  const [modelId, setModelId] = useState<number>(0)
  const [openLogs, setOpenLogs] = useState(false)

  return currentProject ? (
    <>
      <Collapse className="">
        {modelList.map((model, mIdx) => (
          <Panel
            header={
              <div className={`flex items-center justify-between`}>
                {model.languageMap[currentProject.defaultLang]} ({model.devKey})
                <div className={`flex items-center`}>
                  <span className={`block mx-2`}>
                    {model.lastValidate?.replaceAll('T', ' ')}
                  </span>
                  <div
                    className={'bg-[#d9d9d9] z-10 py-0.5 px-1.5 right-0'}
                    onClick={(e) => {
                      e.stopPropagation()
                      setModelId(model.id)
                      setOpenLogs(true)
                    }}>
                    배포 정보
                  </div>
                </div>
              </div>
            }
            key={mIdx}
            className="">
            <div className="">
              <div className="space-y-2">
                {model.componentList ? (
                  model.componentList.map((component, index) => (
                    <ComponentsItem
                      key={index}
                      component={component}
                      activeProject={currentProject}
                      customClassName="bg-gray-100 shadow rounded"
                      disabled={true}
                    />
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
          </Panel>
        ))}
      </Collapse>
      <Drawer
        title={
          <div className={'flex items-center space-x-2'}>
            <div className="flex space-x-2 items-center">
              <span>배포 정보</span>
            </div>
          </div>
        }
        placement="right"
        size="large"
        onClose={() => setOpenLogs(false)}
        open={openLogs}>
        <ProjectsViewValidLogs
          projectUid={currentProject.uid}
          modelId={modelId}
        />
      </Drawer>
    </>
  ) : (
    <></>
  )
}
