import React, { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Collapse, Tabs } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { ProjectInterface } from '@/types/project.type'
import { getModels, getProjectUsage, setCurrentProject } from '@/states/actions'
import { ProjectsViewModels } from './Projects.view.models'
import { ProjectsViewMembers } from './Projects.view.members'
import { useAppDispatch } from '@/states/store'
import { ProjectsViewInfo } from './Projects.view.info'
import { ProjectsViewUsage } from './Projects.view.usage'
import { RootState } from '@/states/reducers'

const { Panel } = Collapse

interface ProjectsProps {
  activeProject: ProjectInterface
}

export const ProjectsView = ({ activeProject }: ProjectsProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { modelList } = projectsState

  // Effect
  useEffect(() => {
    if (activeProject) {
      dispatch(setCurrentProject(activeProject))
      dispatch(getModels(activeProject.uid))
      dispatch(getProjectUsage(activeProject.uid))
    }
  }, [activeProject])

  return (
    <div>
      <Tabs
        defaultActiveKey="info"
        items={[
          {
            label: '기본 정보',
            key: 'info',
            children: <ProjectsViewInfo />,
          },
          {
            label: `모델 (${modelList.length})`,
            key: 'models',
            children: <ProjectsViewModels />,
          },
          {
            label: `멤버 (${activeProject.memberList?.length})`,
            key: 'members',
            children: <ProjectsViewMembers />,
          },
          {
            label: '사용량/요금제',
            key: 'plan',
            children: <ProjectsViewUsage />,
          },
        ]}
      />
    </div>
  )
}
