import Cookies from 'js-cookie'
import {
  USER_ACCESS_TOKEN_KEY,
  USER_ID_KEY,
  USER_INFO_KEY,
  USER_REFRESH_TOKEN_KEY,
} from './cookie.config'
import { CancelTokenSource } from 'axios'
import { setLogout } from '@/utils/auth'

/**
 * 프로필 확인 from Cookie
 * @param context
 * @returns {Promise<{username}|{name}|any|boolean>}
 */
export const getServerSideProfile = (context) => {
  const curUser =
    context.req.cookies[USER_INFO_KEY] &&
    context.req.cookies[USER_ID_KEY] &&
    context.req.cookies[USER_ACCESS_TOKEN_KEY] &&
    context.req.cookies[USER_REFRESH_TOKEN_KEY] &&
    JSON.parse(context.req.cookies[USER_INFO_KEY])

  if (!curUser) {
    setLogout(false)
  }

  return curUser && curUser.name && curUser.email ? curUser : false
}

/**
 * Auth header (Access Token)
 * @returns {{headers: {Authorization: string}}}
 */
export const getAuthHeader = (source?: CancelTokenSource | undefined) => {
  return {
    headers: {
      'Access-Token': `${Cookies.get(USER_ACCESS_TOKEN_KEY)}`,
    },
    cancelToken: source ? source.token : undefined,
  }
}

/**
 * Auth header (Refresh Token)
 * @returns {{headers: {Authorization: string}}}
 */
export const getAuthRefreshHeader = (
  source?: CancelTokenSource | undefined
) => {
  return {
    headers: {
      'Refresh-Token': `${Cookies.get(USER_REFRESH_TOKEN_KEY)}`,
    },
    cancelToken: source ? source.token : undefined,
  }
}
