import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Progress, Tooltip } from 'antd'
import { InfoCircleOutlined, WarningFilled } from '@ant-design/icons'
import { RootState } from '@/states/reducers'
import { UsageInterface } from '@/types'
import { humanFileSize } from '@/utils/helpers'
import { ProgressSize } from 'antd/lib/progress/progress'
import { LAYOUT_WARNING_COLOR } from '@/configs'

interface ProjectsUsageCircleProps {
  usage: UsageInterface | undefined
  label: string
  desc: string
  size?: ProgressSize
  isFile?: boolean
}

export const ProjectsUsageCircle = ({
  usage,
  label,
  desc,
  size,
  isFile = false,
}: ProjectsUsageCircleProps) => {
  const { t, i18n } = useTranslation()

  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
    }),
    shallowEqual
  )

  const { currentProject } = projectsState

  return usage ? (
    <div className="space-y-2">
      {currentProject?.usage?.type !== 'UNLIMITED' ? (
        <div className="flex space-x-2 justify-center relative">
          {typeof usage.limit === 'number' ? (
            <Progress
              type="circle"
              percent={((usage.current / usage.limit) as number) * 100}
              size={size ? size : 'default'}
              strokeColor={
                usage.current >= usage.limit ? LAYOUT_WARNING_COLOR : ''
              }
              format={(percent) => {
                return usage.current >= usage.limit ? (
                  <WarningFilled
                    style={{
                      fontSize: 24,
                      color: LAYOUT_WARNING_COLOR,
                    }}
                  />
                ) : (
                  (percent as number).toFixed(2) + '%'
                )
              }}
              // showInfo={usage.current < usage.limit}
            />
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      <div className="text-center space-y-1">
        {currentProject?.usage?.type !== 'UNLIMITED' ? (
          !isFile ? (
            <div>
              {usage.current.toLocaleString()} /{' '}
              {typeof usage.limit === 'number'
                ? usage.limit.toLocaleString()
                : usage.limit}
            </div>
          ) : (
            <div>
              {humanFileSize(usage.current)} / {humanFileSize(usage.limit)}
            </div>
          )
        ) : !isFile ? (
          <div>
            {usage.current.toLocaleString()}
            {typeof usage.limit === 'number' ? t('countWord') : ''}
          </div>
        ) : (
          <div>{humanFileSize(usage.current)}</div>
        )}
        <Tooltip
          title={desc}
          className="text-xs text-gray-700 flex justify-center items-center space-x-1 cursor-help">
          <span>{label}</span>
          <InfoCircleOutlined />
        </Tooltip>
      </div>
    </div>
  ) : (
    <></>
  )
}
