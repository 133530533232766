import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Layout, Menu, message } from 'antd'
import { LAYOUT_GNB_WIDTH } from '@/configs'
import { ProjectsLogo } from '@/components/projects/Projects.logo'
import { CommonLogo } from '@/components/common/CommonLogo'
import { logout } from '@/states/actions/auth.actions'
import { setLogout } from '@/utils/auth'

const { Sider } = Layout

export const Sidebar = ({ currentProject }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t, i18n } = useTranslation()

  const menuItems = [
    {
      key: 'projects',
      label: '프로젝트',
      onClick: () => {
        navigate('/projects')
      },
    },
    {
      key: 'members',
      label: '멤버',
      onClick: () => {
        navigate('/members')
      },
    },
  ]

  /**
   * 로그아웃
   */
  const goLogout = () => {
    logout()
      .then((res) => {
        setLogout(true)
      })
      .catch((e) => {
        message.error(t('error.wrong'))
      })
  }

  return (
    <Sider
      theme={'light'}
      width={LAYOUT_GNB_WIDTH}
      className={`fixed overflow h-screen `}
      style={{
        overflow: 'hidden',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        zIndex: 100,
      }}>
      {currentProject ? (
        <ProjectsLogo currentProject={currentProject} />
      ) : (
        <CommonLogo />
      )}

      <div className={'py-3 space-y-4 '}>
        <div>
          <Menu
            theme="light"
            selectedKeys={[
              location.pathname === '/projects' ? 'projects' : '',
              location.pathname === '/members' ? 'members' : '',
            ]}
            mode="inline"
            items={menuItems}
          />
        </div>
      </div>
      <div
        className="absolute bottom-0 px-6 py-4 cursor-pointer"
        onClick={() => goLogout()}>
        로그아웃
      </div>
    </Sider>
  )
}
