import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// Import files
import translationFileKo from './ko/common.json'

const resources = {
  ko: { translation: translationFileKo },
}

const userLanguage = window.navigator.language

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('language') || userLanguage || 'ko',
  fallbackLng: 'ko',
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
