export const LAYOUT_DEFAULT_BG = '#F5F5F5'
export const LAYOUT_GNB_WIDTH = 238
export const LAYOUT_GNB_COLLAPSE_WIDTH = 64
export const LAYOUT_SIDEBAR_WIDTH = 238
export const LAYOUT_SIDEBAR_MAX_WIDTH = 500
export const LAYOUT_COMPONENT_TYPE_WIDTH = 236
export const LAYOUT_MODAL_WIDTH = 816
export const LAYOUT_MODAL_LG_WIDTH = 968
export const LAYOUT_CHECKBOX_CELL_WIDTH = 48
export const LAYOUT_DEFAULT_CELL_WIDTH = 160
export const LAYOUT_DEFAULT_CELL_DATE_WIDTH = 150
export const LAYOUT_MIN_CELL_WIDTH = 80
export const LAYOUT_DEFAULT_CELL_HEIGHT = 24
export const LAYOUT_DEFAULT_CELL_BODY_HEIGHT = 32
export const LAYOUT_EXCEL_WIDTH_DOWNLOAD_RATIO = 0.8

export const LAYOUT_WARNING_COLOR = '#F5222D'
export const LAYOUT_SUCCESS_COLOR = '#52c41a'
export const LAYOUT_OKAY_COLOR = '#40A9FF'
