import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { Button, Input } from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { AlertStatus } from '@/components/common/Alert'
import { setLogin } from '@/utils'
import { AuthLoginInterface } from '@/types'
import { loginWithEmailAndPassword } from '@/states/actions'
import { useAppDispatch } from '@/states/store'

const initialLoginValues: AuthLoginInterface = {
  email: '',
  password: '',
}

const LoginPage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation()

  // State

  const [loading, setLoading] = useState<boolean>(false)

  // Effect
  useEffect(() => {
    setTimeout(() => {
      document.getElementById('requestEmail')?.focus() // auto focus
    })
  }, [])

  // Validation
  const validationLoginSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('validation.required'))
      .email(t('validation.email')),
  })

  // Formik Login
  const formikLogin = useFormik({
    initialValues: initialLoginValues,
    validationSchema: validationLoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setStatus(null)

      const req: AuthLoginInterface = {
        email: values.email,
        password: values.password,
      }

      loginWithEmailAndPassword(req)
        .then((res) => {
          setLogin(res.data)
          navigate('/projects')
        })
        .catch((e) => {
          setLoading(false)
          setSubmitting(false)

          // 비밀번호 오류
          setStatus(t('error.credentials'))
        })
        .then(() => {})
    },
  })

  return (
    <>
      <Helmet>
        <title>
          {t('auth.login.title')} · {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <div className="min-h-screen flex items-center justify-center ">
        <div className={'max-w-md w-full'}>
          <div className="pb-5 px-16 space-y-5">
            {!process.env.REACT_APP_PROJECT_ID ? (
              <div className="flex justify-center pb-2">
                <div className="w-24 h-24 memex-logo vertical flex-none"></div>
              </div>
            ) : (
              <></>
            )}
            <div className={'space-y-1'}>
              <h2 className="text-center text-2xl leading-8 font-bold text-gray-900 mb-0">
                {t('auth.login.title')}
              </h2>
              <p className={'text-center text-gray-500'}>
                {t('auth.login.desc')}
              </p>
            </div>
            {/* Form - 시작 */}
            <form onSubmit={formikLogin.handleSubmit} method="POST">
              <AlertStatus
                status={formikLogin.status}
                onClick={() => formikLogin.setStatus(null)}></AlertStatus>
              <div className="space-y-2">
                <div className="block">
                  <label htmlFor="loginEmail" className="">
                    <div className={'mb-0.5'}>{t('email')}</div>
                    <Input
                      id={'loginEmail'}
                      name="email"
                      type="email"
                      onChange={formikLogin.handleChange}
                      value={formikLogin.values.email}
                      placeholder={t('validation.email')}
                      allowClear
                    />
                  </label>
                  {formikLogin.touched.email && formikLogin.errors.email ? (
                    <p className="my-1 text-xs text-red-500">
                      {formikLogin.errors.email}
                    </p>
                  ) : null}
                </div>
                <div className="block">
                  <label htmlFor="loginPassword" className="">
                    <div className={'mb-0.5'}>{t('password')}</div>
                    <Input.Password
                      id="loginPassword"
                      name="password"
                      type="password"
                      onChange={formikLogin.handleChange}
                      value={formikLogin.values.password}
                      placeholder={t('validation.password')}
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                  </label>
                  {formikLogin.touched.password &&
                  formikLogin.errors.password ? (
                    <p className="my-1 text-xs text-red-500">
                      {formikLogin.errors.password}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="mt-4">
                <Button
                  type={'primary'}
                  htmlType={'submit'}
                  block
                  disabled={
                    loading ||
                    !formikLogin.values.email ||
                    !formikLogin.values.password
                  }
                  loading={loading}>
                  로그인
                </Button>
              </div>
            </form>
            {/* Form - 끝 */}
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginPage
