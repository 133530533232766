import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { DefaultLayout } from '@/components/common/DefaultLayout'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import {
  Button,
  DatePicker,
  DatePickerProps,
  Drawer,
  Input,
  InputNumber,
  Pagination,
  Select,
  Space,
  Table,
  Typography,
} from 'antd'
import { useTranslation } from 'react-i18next'
import { UserInterface } from '@/types/user.type'
import { PaginationInterface } from '@/types'
import { UsersView } from '@/components/users/Users.view'
import { getMembers } from '@/states/actions/members.actions'
import { DEFAULT_CONTENTS_PAGE_SIZE } from '@/configs'
import { SearchOutlined } from '@ant-design/icons'

const { Option } = Select

const UsersPage = () => {
  const { t, i18n } = useTranslation()

  // Params
  const [searchParams, setSearchParams] = useSearchParams()

  // State
  const [loading, setLoading] = useState<boolean>(true)
  const [activeUser, setActiveUser] = useState<UserInterface | null>(null)
  const [users, setUsers] = useState<UserInterface[]>([])
  const [sort, setSort] = useState<string>('DATE_CREATE.DESC')
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')
  const [authTypes, setAuthTypes] = useState<string[]>([])
  const [startCount, setStartCount] = useState<number>(-1)
  const [endCount, setEndCount] = useState<number>(-1)
  const [pagination, setPagination] = useState<PaginationInterface>({
    total: 0,
    pageSize: searchParams.get('size')
      ? Number(searchParams.get('size'))
      : DEFAULT_CONTENTS_PAGE_SIZE,
    current: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
  })

  const [openUser, setOpenUser] = useState(false)

  // Effect
  useEffect(() => {
    fetchUsers(pagination, sort)
  }, [])

  useEffect(() => {
    if (!openUser) {
      fetchUsers(pagination, sort)
    }
  }, [openUser])

  // Table columns
  const tableCols = [
    {
      title: '이름',
      key: 'name',
      render: (user) => (
        <div
          className={'flex items-center space-x-2 cursor-pointer'}
          onClick={() => openUserDrawer(user)}>
          <div
            className={'w-6 h-6 bg-cover bg-gray-300 flex-none'}
            style={{
              backgroundImage: `url(${user?.image?.path})`,
            }}></div>
          <div className="flex space-x-2 items-center">{user.name}</div>
        </div>
      ),
    },
    {
      title: '이메일',
      key: 'email',
      render: (user) => <>{user.email}</>,
    },
    {
      title: '이메일 수신동의',
      key: 'newsLetter',
      render: (user) => <>{user.newsLetter ? '동의' : '-'}</>,
    },
    {
      title: '프로젝트 수',
      key: 'projectCount',
      render: (user) => <>{user.projectCount}</>,
    },
    {
      title: '권한',
      key: 'roleList',
      render: (user) => (
        <div className="space-x-2">
          {user.roleList
            ? user.roleList.map((role, rIdx) => (
                <span
                  key={rIdx}
                  className="border border-gray-300 rounded-sm px-1 font-normal text-xs">
                  {role}
                </span>
              ))
            : '-'}
        </div>
      ),
    },
    {
      title: '생성일',
      key: 'createdAt',
      render: (user) => (
        <>
          {moment(user.date.createdAt, 'YYYYMMDDHHmmss').format(
            'YYYY-MM-DD HH:mm:ss'
          )}
        </>
      ),
    },
  ]

  /**
   * 프로젝트 열기
   * @param user
   */
  const openUserDrawer = (user) => {
    setOpenUser(true)
    setActiveUser(user)
  }

  /**
   * 회원 목록 가져오기
   * @param paging
   * @param sortBy
   */
  const fetchUsers = (paging, sortBy) => {
    const req = {
      page: paging.current - 1,
      size: paging.pageSize,
      direction: sortBy.split('.')[1],
      orderCond: sortBy.split('.')[0],
      name: name ? name : '',
      dateCond: {
        start: startDate != '' ? startDate : null,
        end: endDate != '' ? endDate : null,
      },
      countCond: {
        start: startCount > -1 ? startCount : null,
        end: endCount > -1 ? endCount : null,
      },
      projectAuthList: authTypes,
    }

    getMembers(req)
      .then((res) => {
        setUsers(res.data.list)
        setLoading(false)

        const pageInfo = res.data.pageInfo

        setPagination({
          total: pageInfo.totalElements,
          pageSize: pageInfo.size,
          current: pageInfo.page + 1,
        })
      })
      .catch((e) => {
        setUsers([])
        setLoading(false)

        setPagination({
          total: 0,
          pageSize: DEFAULT_CONTENTS_PAGE_SIZE,
          current: 1,
        })
      })
  }

  /**
   * Table 정보 수정
   * @param pagination
   * @param filters
   * @param sorter
   * @param extra
   */
  const onTableChange = (pagination, filters, sorter, extra) => {
    // setSort(sorter.field)
  }

  /**
   * 페이지네이션 변경
   * @param page
   * @param pageSize
   */
  const onHandleChangePagination = (page, pageSize) => {
    fetchUsers(
      {
        total: pagination.total,
        pageSize: pageSize,
        current: page,
        changing: true,
      },
      sort
    )
  }

  /**
   * 검색어 변경
   * @param name
   */
  const onHandleChangeName = (name) => {
    setName(name)
  }

  /**
   * 가입일 시작
   * @param date
   * @param dateString
   */
  const onHandleChangeStartDate: DatePickerProps['onChange'] = (
    date,
    dateString
  ) => {
    setStartDate(dateString)
  }

  /**
   * 가입일 종료
   * @param date
   * @param dateString
   */
  const onHandleChangeEndDate: DatePickerProps['onChange'] = (
    date,
    dateString
  ) => {
    setEndDate(dateString)
  }

  /**
   * 프로젝트 내 권한 변경
   * @param list
   */
  const onHandleChangeAuthType = (list: string[]) => {
    setAuthTypes(list)
  }

  /**
   * 프로젝트 가입 수 시작
   * @param count
   */
  const onHandleStartCount = (count: number | null) => {
    if (count != null) {
      setStartCount(count)
    } else {
      setStartCount(-1)
    }
  }

  /**
   * 프로젝트 가입 수 종료
   * @param count
   */
  const onHandleEndCount = (count: number | null) => {
    if (count != null) {
      setEndCount(count)
    } else {
      setEndCount(-1)
    }
  }

  const onHandleChangeSort = (sortChanged) => {
    setSort(sortChanged)
  }

  return (
    <DefaultLayout>
      <Helmet>
        <title>
          {t('members')} · {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <div className="mb-10">
        <Typography.Title level={3}>멤버 목록</Typography.Title>
      </div>
      <div className="w-full mb-5">
        <Table
          className="w-full"
          // @ts-ignore
          columns={tableCols}
          dataSource={users}
          rowKey="id"
          loading={loading}
          pagination={false}
          title={() => (
            <div className={`space-y-2`}>
              <div
                className={`w-full flex items-center justify-between space-x-5`}>
                <div
                  className={`w-3/4 flex justify-start items-center space-x-5`}>
                  <div className={`w-1/3`}>
                    <Space direction="horizontal">
                      <DatePicker
                        onChange={onHandleChangeStartDate}
                        placeholder="Start date"
                      />
                      <DatePicker
                        onChange={onHandleChangeEndDate}
                        placeholder="End date"
                      />
                    </Space>
                  </div>
                  <div className={`w-1/3`}>
                    <Select
                      placeholder={'프로젝트 권한'}
                      mode="multiple"
                      allowClear
                      style={{ width: '100%' }}
                      defaultValue={[]}
                      onChange={onHandleChangeAuthType}
                      options={[
                        {
                          value: 'OWNER',
                          label: '소유자',
                        },
                        {
                          value: 'ADMIN',
                          label: '관리자',
                        },
                        {
                          value: 'USER',
                          label: '편집자',
                        },

                        {
                          value: 'VIEWER',
                          label: '뷰어',
                        },
                      ]}
                    />
                  </div>
                  <div className={`w-1/3`}>
                    <Space>
                      <InputNumber
                        min={0}
                        placeholder="Start number"
                        style={{ width: '100%' }}
                        onChange={onHandleStartCount}
                      />
                      <InputNumber
                        min={0}
                        placeholder="End number"
                        style={{ width: '100%' }}
                        onChange={onHandleEndCount}
                      />
                    </Space>
                  </div>
                </div>
                <div className={`flex justify-end items-center space-x-5`}>
                  <Button
                    onClick={() => fetchUsers(pagination, sort)}
                    icon={<SearchOutlined />}>
                    Search
                  </Button>
                  <div>총 {pagination.total}개</div>
                </div>
              </div>
              <div
                className={`w-full flex items-center justify-between space-x-5`}>
                <Input
                  placeholder="검색어 (이름)"
                  onChange={(e) => {
                    onHandleChangeName(e.target.value)
                  }}
                  onPressEnter={() => fetchUsers(pagination, sort)}
                />
                <div className="flex-none">
                  <Select
                    defaultValue="가입일"
                    style={{ width: 160 }}
                    onChange={onHandleChangeSort}
                    options={[
                      {
                        value: 'DATE_CREATE.DESC',
                        label: '가입일',
                      },
                      {
                        value: 'PROJECTS.DESC',
                        label: '프로젝트 수',
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          )}
          onChange={onTableChange}></Table>
      </div>
      <div>
        <Pagination
          defaultCurrent={1}
          current={pagination.current}
          total={pagination.total}
          pageSize={pagination.pageSize}
          onChange={onHandleChangePagination}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} / 총 ${total}개`
          }
        />
      </div>

      {activeUser ? (
        <Drawer
          title={
            <div className={'flex items-center space-x-2'}>
              <div
                className={'w-6 h-6 bg-cover bg-gray-300 flex-none'}
                style={{
                  backgroundImage: `url(${activeUser?.image?.path})`,
                }}></div>
              <div className="flex space-x-2 items-center">
                {activeUser.name}
              </div>
            </div>
          }
          placement="right"
          size="large"
          onClose={() => setOpenUser(false)}
          open={openUser}>
          <UsersView activeUser={activeUser} />
        </Drawer>
      ) : (
        <></>
      )}
    </DefaultLayout>
  )
}

export default UsersPage
