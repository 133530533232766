import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'antd'
import {
  AuditOutlined,
  CaretDownFilled,
  CaretRightFilled,
  CodeOutlined,
  DiffOutlined,
  KeyOutlined,
  TranslationOutlined,
} from '@ant-design/icons'
import {
  ComponentInterface,
  ComponentTypeInterface,
  ProjectInterface,
} from '@/types'
import { availableComponents } from '@/configs'
import { useAppDispatch } from '@/states/store'

interface ComponentsItemProps {
  component: ComponentInterface
  activeProject: ProjectInterface
  customClassName?: string
  disabled: boolean
}

export const ComponentsItem = ({
  component,
  activeProject,
  customClassName,
  disabled,
}: ComponentsItemProps) => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State
  const [showChildren, setShowChildren] = useState<boolean>(true)
  const [componentTypeObject, setComponentTypeObject] = useState<
    ComponentTypeInterface | null | undefined
  >(null)

  // Effect
  useEffect(() => {
    setComponentTypeObject(
      availableComponents.find((c) => c.type === component.type)
    )
  }, [component])

  return activeProject ? (
    <div className={`${customClassName}`}>
      <div className="flex items-center p-3 space-x-2">
        <div
          className={`flex-none w-6 h-6 flex justify-center items-center ${
            component.type === 'BLOCK' ? 'cursor-pointer' : ''
          }`}
          onClick={() => setShowChildren(!showChildren)}>
          {component.type === 'BLOCK' ? (
            !showChildren ? (
              <CaretRightFilled />
            ) : (
              <CaretDownFilled />
            )
          ) : (
            <></>
          )}
        </div>
        <div className="w-6 h-6 bg-gray-200 text-xs flex-none flex justify-center items-center text-gray-500">
          {componentTypeObject ? <componentTypeObject.icon /> : <></>}
        </div>
        <div className="grow flex overflow-hidden">
          <div
            className={`w-1/2 ${
              component.option?.title ? 'font-bold' : ''
            } text-xs leading-5 truncate`}>
            {component.languageMap[activeProject?.defaultLang]}{' '}
            {component.option && component.option.required ? (
              <Tooltip
                title={t('optionsType.required.desc')}
                className="cursor-help">
                <span className="text-red-500 mr-1">*</span>
              </Tooltip>
            ) : (
              ''
            )}
            <small className="text-xxs text-gray-500 font-normal">
              {component.devKey}
            </small>
          </div>
          <div className="w-1/2 text-xs leading-5 truncate flex space-x-1 items-center">
            <span>{t('componentTypes.' + component.type + '.name')}</span>
            {component.type === 'TITLE' ? (
              <span>
                <Tooltip
                  title={t('optionsType.title.desc')}
                  className="cursor-help">
                  <KeyOutlined />
                </Tooltip>
              </span>
            ) : (
              <></>
            )}
            {component.type === 'TITLE' ||
            component.type === 'SINGLE_LINE_TEXT' ||
            component.type === 'LONG_LINE_TEXT' ||
            component.type === 'RICH_TEXT' ? (
              <span>
                <Tooltip
                  title={t('optionsType.multiLanguage.desc')}
                  className="cursor-help">
                  <TranslationOutlined />
                </Tooltip>
              </span>
            ) : (
              <></>
            )}
            {component.option?.unique ? (
              <span>
                <Tooltip
                  title={t('optionsType.unique.desc')}
                  className="cursor-help">
                  <AuditOutlined />
                </Tooltip>
              </span>
            ) : (
              <></>
            )}
            {component.option?.allowHtml ? (
              <span>
                <Tooltip
                  title={t('optionsType.allowHtml.desc')}
                  className="cursor-help">
                  <CodeOutlined />
                </Tooltip>
              </span>
            ) : (
              <></>
            )}
            {component.option?.multiple ? (
              <span>
                <Tooltip
                  title={t(`optionsType.multiple.${component.type}.desc`)}
                  className="cursor-help">
                  <DiffOutlined />
                </Tooltip>
              </span>
            ) : (
              <></>
            )}
            {component.option?.multipleMedia ? (
              <span>
                <Tooltip
                  title={t('optionsType.multipleMedia.desc')}
                  className="cursor-help">
                  <DiffOutlined />
                </Tooltip>
              </span>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      {component.type === 'BLOCK' ? (
        <div
          className={`pl-10 pr-2.5 space-y-2 pb-3 ${
            showChildren ? '' : 'hidden'
          }`}>
          {component.childList?.map((subComponent, index) => (
            <div key={subComponent.id}>
              <ComponentsItem
                component={subComponent}
                activeProject={activeProject}
                customClassName="bg-gray-100 shadow-sm border border-gray-200 rounded"
                disabled={disabled}
              />
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  ) : (
    <></>
  )
}
