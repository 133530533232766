import {
  ProjectRoleType,
  ProjectUsagePropertiesInterface,
  ProjectUsageType,
} from '@/types'

export const ProjectRoles: ProjectRoleType[] = ['ADMIN', 'USER', 'VIEWER']

/**
 * 프로젝트 요금제 정보
 */
export const ProjectUsageProperties: ProjectUsagePropertiesInterface[] = [
  {
    price: 'FREE',
    availableRoles: ['OWNER', 'ADMIN'],
    maxLangauges: 2,
    maxProjects: 3,
    maxModels: 5,
    maxMembers: 3,
    storage: '100MB',
    maxFileCount: 5,
    maxFileSize: 1024 * 10,
    maxFileResolutions: 1920,
  },
  {
    price: 'BASIC',
    availableRoles: ['OWNER', 'ADMIN', 'USER', 'VIEWER'],
    maxLangauges: 3,
    maxProjects: 5,
    maxModels: 10,
    maxMembers: 10,
    storage: '500MB',
    maxFileCount: 10,
    maxFileSize: 1024 * 25,
    maxFileResolutions: 3840,
  },
  {
    price: 'PRO',
    availableRoles: ['OWNER', 'ADMIN', 'USER', 'VIEWER'],
    maxLangauges: 5,
    maxProjects: 5,
    maxModels: 20,
    maxMembers: null,
    storage: '3GB',
    maxFileCount: 20,
    maxFileSize: 1024 * 50,
    maxFileResolutions: 3840 * 2,
  },
  {
    price: 'UNLIMITED',
    availableRoles: ['OWNER', 'ADMIN', 'USER', 'VIEWER'],
    maxLangauges: null,
    maxProjects: null,
    maxModels: null,
    maxMembers: null,
    storage: null,
    maxFileCount: 30,
    maxFileSize: 1024 * 200,
    maxFileResolutions: 10328,
  },
  {
    price: 'TEST',
    availableRoles: ['OWNER', 'ADMIN'],
    maxLangauges: 1,
    maxProjects: 1,
    maxModels: 1,
    maxMembers: null,
    storage: null,
    maxFileCount: 30,
    maxFileSize: 1024 * 50,
    maxFileResolutions: 3840 * 2,
  },
]

/**
 * 기본 프로젝트 요금제
 */
export const DEFAULT_PROJECT_PLAN: ProjectUsageType = 'FREE'
