import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { DefaultLayout } from '@/components/common/DefaultLayout'
import { SearchOutlined } from '@ant-design/icons'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import {
  Button,
  DatePicker,
  DatePickerProps,
  Drawer,
  Input,
  Pagination,
  Select,
  Space,
  Table,
  Typography,
} from 'antd'
import { useTranslation } from 'react-i18next'
import { ProjectInterface } from '@/types/project.type'
import { PaginationInterface } from '@/types'
import { ProjectsView } from '@/components/projects/Projects.view'
import { DEFAULT_CONTENTS_PAGE_SIZE, ProjectUsageProperties } from '@/configs'
import { getProject, getProjects, setCurrentProject } from '@/states/actions'
import { useAppDispatch } from '@/states/store'

const { Option } = Select

const ProjectsPage = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // Params
  const [searchParams, setSearchParams] = useSearchParams()

  // State
  const [loading, setLoading] = useState<boolean>(true)
  const [activeProject, setActiveProject] = useState<ProjectInterface | null>(
    null
  )
  const [projects, setProjects] = useState<ProjectInterface[]>([])
  const [sort, setSort] = useState<string>('DATE_CREATE.DESC')
  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')
  const [priceTypes, setPriceTypes] = useState<string[]>([])
  const [push, setPush] = useState<boolean>(false)
  const [hasLog, setHasLog] = useState<boolean>(false)
  const [q, setQ] = useState<string>('')
  const [pagination, setPagination] = useState<PaginationInterface>({
    total: 0,
    pageSize: searchParams.get('size')
      ? Number(searchParams.get('size'))
      : DEFAULT_CONTENTS_PAGE_SIZE,
    current: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
  })
  const [openProject, setOpenProject] = useState(false)

  // Effect
  useEffect(() => {
    fetchProjects(pagination, sort)
  }, [])

  useEffect(() => {
    if (!openProject) {
      fetchProjects(pagination, sort)
    }
  }, [openProject])

  // Table columns
  const tableCols = [
    {
      title: '이름',
      key: 'name',
      render: (project) => (
        <div
          className={'flex items-center space-x-2 cursor-pointer'}
          onClick={() => openProjectDrawer(project)}>
          <div
            className={'w-6 h-6 bg-cover bg-gray-300 flex-none'}
            style={{
              backgroundImage: `url(${project?.image?.path})`,
            }}></div>
          <div className="flex space-x-2 items-center">{project.name}</div>
        </div>
      ),
    },
    {
      title: '소유자',
      key: 'owner',
      render: (project) => (
        <span className="font-normal text-xs">
          {
            project.memberList?.find((member) => {
              return member.role == 'OWNER'
            })?.name
          }
        </span>
      ),
    },
    {
      title: '요금제',
      key: 'plan',
      render: (project) => (
        <span className="border border-gray-300 rounded-sm px-1 font-normal text-xs">
          {t(`planTypes.${project.price}.title`)}
        </span>
      ),
    },
    {
      title: '모델 수',
      key: 'modelCount',
      render: (project) => (
        <span className="font-normal text-xs">{project.modelCount}</span>
      ),
    },
    {
      title: '수정일',
      key: 'DATE_UPDATE',
      render: (project) => (
        <>
          {project.date.editedAt
            ? moment(project.date.editedAt, 'YYYYMMDDHHmmss').fromNow()
            : '-'}
        </>
      ),
    },
    {
      title: '생성일',
      key: 'DATE_CREATE',
      render: (project) => (
        <>
          {moment(project.date.createdAt, 'YYYYMMDDHHmmss').format(
            'YYYY-MM-DD HH:mm:ss'
          )}
        </>
      ),
    },
    {
      title: '최근 배포일',
      key: 'validateDate',
      render: (project) => (
        <>
          {project.validateDate
            ? moment(project.validateDate, 'YYYYMMDDHHmmss').format(
                'YYYY-MM-DD HH:mm:ss'
              )
            : '-'}
        </>
      ),
    },
  ]

  /**
   * 프로젝트 열기
   * @param project
   */
  const openProjectDrawer = (project) => {
    getProject(project.uid).then((res) => {
      const projectSet = res.data
      const languageTuple = Object.keys(projectSet.languageMap).map((key) => [
        key,
        projectSet.languageMap[key],
      ])

      projectSet.defaultLang = languageTuple
        ? // @ts-ignore
          languageTuple.find((tuple) => tuple[1] === true)[0]
        : 'KO'

      projectSet.languageList = [
        projectSet.defaultLang,
        ...languageTuple
          .map((tuple) => tuple[0])
          .filter((lang) => lang !== projectSet.defaultLang),
      ]

      if (projectSet) {
        const projectProperties = ProjectUsageProperties.find(
          (p) => p.price === 'UNLIMITED'
        )
        projectSet.availableRoles = projectProperties?.availableRoles
      }

      setOpenProject(true)
      setActiveProject(projectSet)
    })
  }

  /**
   * 프로젝트 정보 가져오기
   * @param paging
   * @param sortBy
   */
  const fetchProjects = (paging, sortBy) => {
    setLoading(true)

    const req = {
      page: paging.current - 1,
      size: paging.pageSize,
      direction: sortBy.split('.')[1],
      orderCond: sortBy.split('.')[0],
      name: q ? q : '',
      push: push ? true : null,
      hasLog: hasLog ? true : null,
      dateCond: {
        start: startDate != '' ? startDate : null,
        end: endDate != '' ? endDate : null,
      },
      priceList: priceTypes,
    }

    getProjects(req)
      .then((res) => {
        const projectsData = res.data.list

        projectsData.forEach((projectSet) => {
          const languageTuple = Object.keys(projectSet.languageMap).map(
            (key) => [key, projectSet.languageMap[key]]
          )

          projectSet.defaultLang = languageTuple
            ? // @ts-ignore
              languageTuple.find((tuple) => tuple[1] === true)[0]
            : 'KO'

          projectSet.languageList = [
            projectSet.defaultLang,
            ...languageTuple
              .map((tuple) => tuple[0])
              .filter((lang) => lang !== projectSet.defaultLang),
          ]

          if (projectSet) {
            const projectProperties = ProjectUsageProperties.find(
              (p) => p.price === 'UNLIMITED'
            )
            projectSet.availableRoles = projectProperties?.availableRoles
          }
        })

        setProjects(projectsData)
        setLoading(false)

        const pageInfo = res.data.pageInfo

        setPagination({
          total: pageInfo.totalElements,
          pageSize: pageInfo.size,
          current: pageInfo.page + 1,
        })
      })
      .catch((e) => {
        setProjects([])
        setLoading(false)

        setPagination({
          total: 0,
          pageSize: DEFAULT_CONTENTS_PAGE_SIZE,
          current: 1,
        })
      })
  }

  /**
   * Table 정보 수정
   * @param pagination
   * @param filters
   * @param sorter
   * @param extra
   */
  const onTableChange = (pagination, filters, sorter, extra) => {
    // setSort(sorter.field)
  }

  /**
   * 페이지네이션 변경
   * @param page
   * @param pageSize
   */
  const onHandleChangePagination = (page, pageSize) => {
    fetchProjects(
      {
        total: pagination.total,
        pageSize: pageSize,
        current: page,
        changing: true,
      },
      sort
    )
  }

  /**
   * 검색어 변경
   * @param name
   */
  const onHandleChangeName = (name) => {
    setQ(name)
  }

  /**
   * 정렬 변경
   * @param name
   */
  const onHandleChangeSort = (sortChanged) => {
    setSort(sortChanged)
  }

  /**
   * 생성일 시작 변경
   * @param date
   * @param dateString
   */
  const onHandleChangeStartDate: DatePickerProps['onChange'] = (
    date,
    dateString
  ) => {
    setStartDate(dateString)
  }

  /**
   * 생성일 종료 변경
   * @param date
   * @param dateString
   */
  const onHandleChangeEndDate: DatePickerProps['onChange'] = (
    date,
    dateString
  ) => {
    setEndDate(dateString)
  }

  /**
   * 요금제 타입 변경
   * @param list
   */
  const onHandleChangePriceType = (list: string[]) => {
    setPriceTypes(list)
  }

  /**
   * 배포 종류 변경
   * @param value
   */
  const onHandleChangeDeployType = (value: string) => {
    switch (value) {
      case 'ALL':
        setHasLog(false)
        setPush(false)
        break

      case 'PUSH':
        setHasLog(false)
        setPush(true)
        break

      case 'LOG':
        setHasLog(true)
        setPush(true)
        break

      default:
        setHasLog(false)
        setPush(false)
        break
    }
  }

  return (
    <DefaultLayout>
      <Helmet>
        <title>
          {t('project')} · {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <div className="mb-10">
        <Typography.Title level={3}>프로젝트 목록</Typography.Title>
      </div>

      <div className="w-full mb-5">
        <Table
          className="w-full"
          // @ts-ignore
          columns={tableCols}
          dataSource={projects}
          rowKey="uid"
          loading={loading}
          pagination={false}
          title={() => (
            <div className={`space-y-2`}>
              <div className="flex justify-between items-center space-x-5">
                <div
                  className={`w-3/4 flex justify-start items-center space-x-5`}>
                  <Space direction="horizontal">
                    <DatePicker
                      onChange={onHandleChangeStartDate}
                      placeholder="Start date"
                    />
                    <DatePicker
                      onChange={onHandleChangeEndDate}
                      placeholder="End date"
                    />
                  </Space>
                  <div className={`w-1/4`}>
                    <Select
                      placeholder={'요금제 선택'}
                      mode="multiple"
                      allowClear
                      style={{ width: '100%' }}
                      defaultValue={[]}
                      onChange={onHandleChangePriceType}
                      options={ProjectUsageProperties.map((plan) => ({
                        label: t(`planTypes.${plan.price}.title`),
                        value: plan.price,
                      }))}
                    />
                  </div>
                  <div className={`w-1/5`}>
                    <Select
                      style={{ width: '100%' }}
                      defaultValue={'전체'}
                      onChange={onHandleChangeDeployType}
                      options={[
                        {
                          value: 'ALL',
                          label: '전체',
                        },
                        {
                          value: 'PUSH',
                          label: '데이터 연결',
                        },
                        {
                          value: 'LOG',
                          label: '배포중',
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className={`flex justify-end items-center space-x-5`}>
                  <Button
                    onClick={() => fetchProjects(pagination, sort)}
                    icon={<SearchOutlined />}>
                    Search
                  </Button>
                  <div>총 {pagination.total}개</div>
                </div>
              </div>
              <div className="flex justify-between items-center space-x-5">
                <Input
                  placeholder="검색어 (이름)"
                  onChange={(e) => onHandleChangeName(e.target.value)}
                  allowClear={true}
                  onPressEnter={() => fetchProjects(pagination, sort)}
                />
                <div className="flex-none">
                  <Select
                    defaultValue="프로젝트 생성일순"
                    style={{ width: 160 }}
                    onChange={onHandleChangeSort}
                    options={[
                      {
                        value: 'DATE_CREATE.DESC',
                        label: '프로젝트 생성일순',
                      },
                      {
                        value: 'DATE_UPDATE.DESC',
                        label: '프로젝트 수정일순',
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          )}
          onChange={onTableChange}></Table>
      </div>
      <div>
        <Pagination
          defaultCurrent={1}
          current={pagination.current}
          total={pagination.total}
          pageSize={pagination.pageSize}
          onChange={onHandleChangePagination}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} / 총 ${total}개`
          }
        />
      </div>
      {activeProject ? (
        <Drawer
          title={
            <div className={'flex items-center space-x-2'}>
              <div
                className={'w-6 h-6 bg-cover bg-gray-300 flex-none'}
                style={{
                  backgroundImage: `url(${activeProject?.image?.path})`,
                }}></div>
              <div className="flex space-x-2 items-center">
                {activeProject.name}
              </div>
            </div>
          }
          placement="right"
          size="large"
          onClose={() => {
            dispatch(setCurrentProject(null))
            setOpenProject(false)
          }}
          open={openProject}>
          <ProjectsView activeProject={activeProject} />
        </Drawer>
      ) : (
        <></>
      )}
    </DefaultLayout>
  )
}

export default ProjectsPage
