import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Tag } from 'antd'
import { RootState } from '@/states/reducers'

export const ProjectsViewInfo = () => {
  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { currentProject } = projectsState

  return currentProject ? (
    <div className="space-y-4">
      <div>
        <h4 className="font-medium mb-1">언어</h4>
        <div className="flex items-center">
          {currentProject.languageMap ? (
            Object.entries(currentProject.languageMap).map((langObj, lang) => (
              <Tag color={langObj[1] ? 'success' : 'default'} key={lang}>
                {langObj[0]}
              </Tag>
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
      <div>
        <h4 className="font-medium mb-1">설명</h4>
        <p>{currentProject.description}</p>
      </div>
      {/* <div>
        <Button type="primary" danger>
          프로젝트 삭제
        </Button>
      </div> */}
    </div>
  ) : (
    <></>
  )
}
