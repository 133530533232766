import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from '@/states/store'

import './App.css'
import './styles/antd.less'
import './styles/tailwind.css'
import './styles/app.scss'

import PrivateRoute from './components/common/ProtectedRoute'
import Home from './pages/Home'
import LoginPage from './pages/Login'
import ProjectsPage from './pages/Projects'
import UsersPage from './pages/Users'

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          {/* <PublicRoute restricted={false} component={Home} path="/" exact />
        <PublicRoute restricted={true} component={LoginPage} path="/LoginPage" exact /> */}

          <Route index path="/" element={<Home />} />
          <Route element={<PrivateRoute authentication={false} />}>
            <Route path="/login" element={<LoginPage />} />
          </Route>
          <Route element={<PrivateRoute authentication={true} />}>
            <Route path="/projects" element={<ProjectsPage />} />
          </Route>
          <Route element={<PrivateRoute authentication={true} />}>
            <Route path="/members" element={<UsersPage />} />
          </Route>
          <Route path="*" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  )
}

export default App
